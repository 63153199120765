import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { useMenuItemsSocial } from '../../constants/menu';
import { LinkMenuItemSocial } from '../LinkMenuItemSocial';

export function MenuItemsSocial() {
  const { t } = useTranslation();

  const menuItemsSocial = useMenuItemsSocial();

  const getAffix = (index: number) => {
    switch (index) {
      case menuItemsSocial.length - 1:
        return '';
      case menuItemsSocial.length - 2:
        return ` ${t('list.or')} `;
      default:
        return ', ';
    }
  };

  return (
    <>
      {menuItemsSocial.map((item, index) => (
        <Fragment key={`menuItemSocial${index}`}>
          <LinkMenuItemSocial {...item} />
          {getAffix(index)}
        </Fragment>
      ))}
    </>
  );
}
