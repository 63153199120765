import { MDXBody } from '../../components/MDXBody';
import { SEO } from '../../components/SEO';

import { Props } from './types';

export function Page({
  data: {
    mdx: {
      body,
      frontmatter: {
        description,
        title,
      },
    },
  },
}: Props) {
  return (
    <>
      <SEO description={description} title={title} />
      <MDXBody>
        {body}
      </MDXBody>
    </>
  )
}
