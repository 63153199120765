export function Signature(props) {
  return (
    <svg
      viewBox="0 0 836.07 413.22"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g>
        <path d="M219.1,8c0,13.79-24.39,135.81,57.47,157.85C376.19,192.67,377,129.07,377,129.07s-28.35-31.42-60.52,43.28C223.7,314.5,152.2,360.48,75.81,388.83c-54.4,10-108-100.38-24.52-141.76C177,218.72,197.63,363.68,299.54,368.28c62.32,8.05,72.81-87.49,72.81-87.49s-12.26-33-23.75,3.06c-13.79,57.47,37.55,75.1,54.4,74.33,15.33-.77,43.68-28.35,42.92-62.83s-22.23-23-18.5-.77c6.89,19.41,30.78,83.52,94.36,75.1,69.82-9.26,96.54-119.54,102.67-208.43,10-86.59-114.94-189.26-82.86,49.62,24.63,128.15,46,192.83,93.54,190.54,36.18-1.74,24.57-89.2,9.25-80s-11,31.52,7.41,29.22,20.16-2.34,26.29-7.7,7.72-38.69-8.42-27.65-12.26,84.29,21.7,83c36.6-1.43,33.47-65.35,13.55-82.2s-26.83,26.88-.76,26.82c11.05,0,47.5-9.2,51.33-42.91,43.68,23,103,88.11,54.41,101.52-51.34,14.18-68.2-16.47-68.2-16.47" />
      </g>
    </svg>
  );
}
