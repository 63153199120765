import { useMemo } from 'react';

import { useIntersectionObserver } from '../../constants/observer';
import { SvgSignature } from '../Svg';

import { Styles } from './styled';
import { Props } from './types';

export function Signature({ author }: Props) {
  const Svg = useMemo(() => {
    switch (author) {
      case 'Lubos':
        return SvgSignature;
      default:
        return undefined;
    }
  }, [author]);

  const className = useIntersectionObserver(
    '.signature > svg',
    (entries) => Math.round(entries[0].intersectionRatio) === 1
      ? 'visible'
      : undefined,
    {
      threshold: [0, 1],
    },
  );

  if (!Svg) {
    return null;
  }

  return (
    <Styles>
      <div className="signature">
        <Svg className={className} />
      </div>
    </Styles>
  );
}
