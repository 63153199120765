import styled from 'styled-components';

export const Styles = styled.nav`
  .signature {
    margin-left: calc(var(--spacing) * 1);
    margin-top: calc(var(--spacing) * 2);

    > svg {
      background: none;
      display: block;
      height: auto;
      max-width: 200px;
      width: 30%;

      path {
        fill: none;
        stroke: var(--cSecondary);
        stroke-dasharray: 3265;
        stroke-dashoffset: 3266;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-miterlimit: 10;
        stroke-width: 1rem;
      }

      &.visible {
        path {
          animation: signature 2s cubic-bezier(.55, .085, .68, .53) forwards;
        }
      }
    }

    @media (min-width: 768px) {
      margin-top: calc(var(--spacing) * 3);
    }

    @media (min-width: 1200px) {
      margin-top: calc(var(--spacing) * 4);
    }
  }

  @keyframes signature {
    to {
      stroke-dashoffset: 0;
    }
  }
`;
