import { MDXProvider } from '@mdx-js/react';
import { Link } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import { MenuItemsSocial } from '../MenuItemsSocial';
import { Signature } from '../Signature';

import { Props } from './types';

const components = {
  MenuItemsSocial: (props) => <MenuItemsSocial {...props} />,
  Signature: (props) => <Signature {...props} />,
  a: ({ href, ...rest }: { href: string }) => {
    if (href.startsWith('mailto') || href.startsWith('http')) {
      // eslint-disable-next-line jsx-a11y/anchor-has-content
      return <a href={href} {...rest} />
    }

    return <Link to={href} {...rest} />
  },
};

export function MDXBody({ children }: Props) {
  return (
    <MDXProvider components={components}>
      <MDXRenderer>
        {children}
      </MDXRenderer>
    </MDXProvider>
  );
}
